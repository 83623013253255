/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestApprovalType,
    AccessRequestApprovalTypeFromJSON,
    AccessRequestApprovalTypeFromJSONTyped,
    AccessRequestApprovalTypeToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface PermissionResponse
 */
export interface PermissionResponse {
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    permissionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    organizationGroupId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PermissionResponse
     */
    usedInRolesCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PermissionResponse
     */
    tags?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof PermissionResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    canBeRequestedByUsers?: boolean;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof PermissionResponse
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requireUserOrganizationInMinSide?: boolean | null;
    /**
     * 
     * @type {AccessRequestApprovalType}
     * @memberof PermissionResponse
     */
    accessRequestApprovalType?: AccessRequestApprovalType;
    /**
     * 
     * @type {boolean}
     * @memberof PermissionResponse
     */
    requiresMfa?: boolean;
}

export function PermissionResponseFromJSON(json: any): PermissionResponse {
    return PermissionResponseFromJSONTyped(json, false);
}

export function PermissionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissionId': !exists(json, 'permissionId') ? undefined : json['permissionId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'organizationGroupId': !exists(json, 'organizationGroupId') ? undefined : json['organizationGroupId'],
        'usedInRolesCount': !exists(json, 'usedInRolesCount') ? undefined : json['usedInRolesCount'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'canBeRequestedByUsers': !exists(json, 'canBeRequestedByUsers') ? undefined : json['canBeRequestedByUsers'],
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
        'requireUserOrganizationInMinSide': !exists(json, 'requireUserOrganizationInMinSide') ? undefined : json['requireUserOrganizationInMinSide'],
        'accessRequestApprovalType': !exists(json, 'accessRequestApprovalType') ? undefined : AccessRequestApprovalTypeFromJSON(json['accessRequestApprovalType']),
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
    };
}

export function PermissionResponseToJSON(value?: PermissionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissionId': value.permissionId,
        'name': value.name,
        'shortName': value.shortName,
        'description': value.description,
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'organizationGroupId': value.organizationGroupId,
        'usedInRolesCount': value.usedInRolesCount,
        'tags': value.tags,
        'userType': UserTypeToJSON(value.userType),
        'canBeRequestedByUsers': value.canBeRequestedByUsers,
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
        'requireUserOrganizationInMinSide': value.requireUserOrganizationInMinSide,
        'accessRequestApprovalType': AccessRequestApprovalTypeToJSON(value.accessRequestApprovalType),
        'requiresMfa': value.requiresMfa,
    };
}


