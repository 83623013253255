import React, { useState, useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Form, MultiSelect, Dropdown, SelectOption } from 'common.ui';
import { PermissionResponse } from 'api/adgang/models';
import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';

import './PermissionsMultiSelect.scss';

export type PermissionsMultiSelectOption = {
  permissionId: string;
  permissionName: string;
  applicationName?: string;
};

type PermissionsMultiSelectProps<T> = {
  applicationOptions: SelectOption[];
  permissions: PermissionResponse[];
  name: string;
  label: string;
  okLabel: string;
  form: Form<T>;
  onChange?: (model: string[]) => void;
  onSelectionChange?: (val: string[]) => void;
  ignoreClicksOutside?: boolean;

  onClose?: () => void;
  triggerOnChangeAlways?: boolean; // Trigger onChange when closing even if values are the same
  isInitiallyOpen?: boolean; // MultiSelect is initally open and options expanded
};

function PermissionsMultiSelect<T>({
  applicationOptions,
  permissions,
  form,
  name,
  label,
  okLabel,
  triggerOnChangeAlways,
  isInitiallyOpen,
  onSelectionChange,
  onChange: onChangeCallBack,
  onClose,
  ignoreClicksOutside
}: PermissionsMultiSelectProps<T>) {
  const [currentApplicationId, setCurrentApplicationId] = useState<string>('global');
  const [currentOptions, setCurrentOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    const options = permissions
      .filter((o) => (o.applicationId || 'global') === currentApplicationId)
      .map(
        (o) =>
          ({
            id: o.permissionId?.toString() ?? '',
            text: o.name ?? ''
          } as SelectOption)
      );
    setCurrentOptions(options);
  }, [permissions, applicationOptions, currentApplicationId]);

  const containerRef = useRef(null);

  return (
    <div className='permissions-multiselect-container' ref={containerRef}>
      <IconCross style={{ float: 'right', width: '8px' }} onClick={onClose} className='close-multiselect' />
      <b>Filtrer utvalg på:</b>
      <div style={{ marginTop: '1.4em' }}>
        <Dropdown
          id='appfilter'
          labelText='Applikasjon'
          value={currentApplicationId}
          options={applicationOptions}
          onChange={(e: any) => {
            const applicationId = e.target.value;
            setCurrentApplicationId(applicationId);
          }}
        />
      </div>

      <Controller
        // @ts-ignore
        defaultValue={form.propsform.model && form.propsform.model[name]}
        control={form.control}
        // @ts-ignore
        name={name}
        // key={currentApplicationId}
        render={({ onChange, value }) => (
          <MultiSelect
            label={label}
            okLabel={okLabel}
            id={name}
            triggerOnChangeAlways={triggerOnChangeAlways}
            isInitiallyOpen={isInitiallyOpen}
            ignoreClicksOutside={ignoreClicksOutside}
            onSelectionChange={onSelectionChange}
            onChange={(model: any) => {
              onChange(model);
              if (onChangeCallBack) onChangeCallBack(model);
            }}
            options={currentOptions}
            selectedIds={value}
            doNotDisplayOptionsOnTop
            wrapperRef={containerRef}
          />
        )}
      />
    </div>
  );
}

export { PermissionsMultiSelect };
