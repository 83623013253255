import React, { useState, useCallback, useEffect } from 'react';
import { Button, SelectOption, useBoundForm, Table, Th, LoadingSpinner } from 'common.ui';
import {
  GenericValidationError,
  UserType,
  RoleCreateRequest,
  RoleUpdateRequest,
  PermissionResponse,
  UserAuthenticationConstraint,
  ApplicationResponse,
  OrganizationGroupType,
  OrganizationGroupResponse,
  AdgangAccessControl,
  ResourceFilter,
  AccessRequestApprovalType
} from 'api/adgang/models';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useApplications, usePermissions, useOrganizationGroups } from 'api/hooks';
import { useUserAuthenticationConstraints } from 'api/hooks/useUserAuthenticationConstraint';
import { PermissionsMultiSelect } from 'components/permissions/PermissionsMultiSelect';
import RoutePaths from 'RoutePaths';
import { ReactComponent as IconCross } from 'assets/icons/cross-icon.svg';

import { useAccessControlList } from 'hooks/access/useAccessControlList';
import OrganizationGroupSelector, {
  OrganizationGroup
} from 'components/organizationgroups/OrganizationGroupSelector';

type RoleFormValue = RoleCreateRequest | RoleUpdateRequest;

type RoleFormProps<T extends RoleFormValue> = {
  initialValue?: T;
  resourceBasedAcl?: AdgangAccessControl[];
  isUnlocksExternalApproverRightsRole?: boolean;
  onSave: (model: T) => Promise<void>;
  isUpdate?: boolean;
  onSaveLabel: string;
};

type Role = {
  applicationId: string;
  name: string;
  shortName: string;
  userType: string;
  canBeRequestedByUsers: string;
  description: string;
  tags: string;
  permissionIds: string[];
  organizationGroupId: string;
  organizationGroupIds: string[];
  userAuthenticationConstraint?: UserAuthenticationConstraint;
  requireUserOrganizationInMinSide?: string;
  organizationGroupType?: OrganizationGroupType;
  accessRequestApprovalType: AccessRequestApprovalType;
  requiresMfa: boolean;
};

type AddRoleScreenProps<T extends RoleFormValue> = {
  applications: ApplicationResponse[];
  applicationOptions: SelectOption[];
  permissionApplicationOptions: SelectOption[];
  permissions: PermissionResponse[];
  organizationGroups: OrganizationGroupResponse[];
  organizationGroupOptions: SelectOption[];
  initialValue?: T;
  isUpdate?: boolean;
  resourceBasedAcl?: AdgangAccessControl[];
  isUnlocksExternalApproverRightsRole?: boolean;
  onSave: (model: T) => Promise<void>;
  onSaveLabel: string;
};

function RoleForm<T extends RoleFormValue>({
  initialValue,
  resourceBasedAcl,
  isUnlocksExternalApproverRightsRole,
  onSave,
  isUpdate,
  onSaveLabel
}: RoleFormProps<T>): React.ReactElement<RoleFormProps<T>> {
  const [, hasAccess] = useAccessControlList();
  const [applicationOptions, applications] = useApplications(
    undefined,
    ResourceFilter.Role,
    !hasAccess(AdgangAccessControl.RolesViewShowNoAppFilter)
  );
  const [permissionApplicationOptions] = useApplications();
  const [, permissions] = usePermissions();
  const [organizationGroupOptions, organizationGroups] = useOrganizationGroups();

  return applications &&
    applicationOptions &&
    permissions &&
    organizationGroups &&
    organizationGroupOptions ? (
    <RoleFormScreen
      onSave={onSave}
      onSaveLabel={onSaveLabel}
      resourceBasedAcl={resourceBasedAcl}
      initialValue={initialValue}
      applications={applications}
      isUnlocksExternalApproverRightsRole={isUnlocksExternalApproverRightsRole}
      permissionApplicationOptions={permissionApplicationOptions ?? []}
      applicationOptions={applicationOptions}
      permissions={permissions}
      isUpdate={isUpdate}
      organizationGroups={organizationGroups}
      organizationGroupOptions={organizationGroupOptions}
    />
  ) : (
    <LoadingSpinner />
  );
}

function RoleFormScreen<T extends RoleFormValue>({
  applications,
  applicationOptions,
  permissionApplicationOptions,
  permissions,
  isUpdate,
  organizationGroups,
  organizationGroupOptions,
  isUnlocksExternalApproverRightsRole,
  initialValue,
  resourceBasedAcl,
  onSave,
  onSaveLabel
}: AddRoleScreenProps<T>) {
  // const [, hasAccess] = useAccessControlList();
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [userAuthenticationConstraints] = useUserAuthenticationConstraints(false, true);
  const [showAddPermissions, setShowAddPermissions] = useState(false);
  const [requireUserAuthConstraint, setRequireUserAuthConstraint] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<ApplicationResponse | undefined>(undefined);
  const [selectedOrganizationGroups, setSelectedOrganizationGroups] = useState<OrganizationGroup[]>([]);

  const history = useHistory();

  // @ts-ignore
  const [values, setValues] = useState<Role>({
    ...initialValue,
    applicationId: initialValue?.isGlobal ? 'global' : initialValue?.applicationId ?? '',
    permissionIds: initialValue?.permissionIds ? initialValue.permissionIds.map((p) => p.toString()) : [],
    organizationGroupIds: initialValue?.organizationGroupIds
      ? initialValue.organizationGroupIds.map((orgId) => orgId.toString())
      : [],
    organizationGroupId: initialValue?.organizationGroupIds
      ? initialValue.organizationGroupIds[0]?.toString()
      : '',
    canBeRequestedByUsers: initialValue?.canBeRequestedByUsers ? 'yes' : 'no',
    requireUserOrganizationInMinSide: initialValue?.requireUserOrganizationInMinSide ? 'yes' : 'no',
    requiresMfa: !!initialValue?.requiresMfa
  });

  const [formAccessRequestApprovalTypeSeq, setFormAccessRequestApprovalTypeSeq] = useState(0);

  const hasAccess = (accessControl: AdgangAccessControl): boolean => {
    if (!isUpdate) return true;

    return !!resourceBasedAcl && resourceBasedAcl.some((x) => x === accessControl);
  };

  const isReadOnly = !hasAccess(AdgangAccessControl.RoleEdit);

  const OnFormSubmit = useCallback(
    async (submitModel: Role) => {
      const model = {
        ...submitModel,
        permissionIds: values.permissionIds,
        organizationGroupIds: selectedOrganizationGroups.map((orgGroup: OrganizationGroup) => orgGroup.id)
      };

      let orgGroupIds = ['-1'];

      if (isUnlocksExternalApproverRightsRole) {
        orgGroupIds = model.organizationGroupIds;
      } else if (model.organizationGroupIds !== undefined && model.organizationGroupId !== '') {
        orgGroupIds = [model.organizationGroupId];
      }

      if (model.userType === UserType.Internal) {
        orgGroupIds = [];
      }

      setValues(model);
      try {
        // @ts-ignore
        await onSave({
          applicationId: model.applicationId !== 'global' ? model.applicationId : undefined,
          isGlobal: model.applicationId === 'global',
          description: model.description,
          name: model.name,
          shortName: model.shortName,
          userType: model.userType ? (model.userType as UserType) : undefined,
          tags: model.tags,
          canBeRequestedByUsers: model.canBeRequestedByUsers === 'yes',
          permissionIds: model.permissionIds.map((p) => +p),
          organizationGroupIds: orgGroupIds,
          userAuthenticationConstraint: model.userAuthenticationConstraint
            ? (model.userAuthenticationConstraint as UserAuthenticationConstraint)
            : undefined,
          requireUserOrganizationInMinSide: orgGroupIds.length >= 1 && orgGroupIds[0] !== '-1',
          organizationGroupType: model.organizationGroupType
            ? (model.organizationGroupType as OrganizationGroupType)
            : undefined,
          accessRequestApprovalType: model.accessRequestApprovalType,
          requiresMfa: (model.requiresMfa as unknown) === 'on'
        });

        setApiErrors(undefined);
        history.push(RoutePaths.roles);
      } catch (e) {
        // @ts-ignore
        if (e.json) {
          // @ts-ignore
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    [history, onSave, values.permissionIds, selectedOrganizationGroups]
  );

  const {
    form,
    FormContainer,
    RadioGroup,
    Checkbox,
    Dropdown,
    Input,
    DisplayErrors,
    TextArea
  } = useBoundForm<Role>({
    onSubmit: async (e) => {
      await OnFormSubmit(e);
    },
    errors: apiErrors,
    model: values
  });

  useEffect(() => {
    setSelectedApplication(applications?.find((a) => a.id === values?.applicationId));
  }, [applications, values]);

  useEffect(() => {
    if (isUnlocksExternalApproverRightsRole) {
      let selectedGroups: OrganizationGroup[] = [];

      // @ts-ignore
      selectedGroups = values.organizationGroupIds
        .filter((orgId) => orgId !== '0')
        .map((orgId) => {
          if (organizationGroupOptions.some((el) => el.id === orgId)) {
            return organizationGroupOptions.find((el) => el.id === orgId) as OrganizationGroup;
          }
        });
      setSelectedOrganizationGroups(selectedGroups as OrganizationGroup[]);
    }
  }, [organizationGroups, values]);

  useEffect(() => {
    const required =
      values.applicationId === 'global' ||
      (selectedApplication &&
        selectedApplication?.userAuthenticationConstraint ===
          UserAuthenticationConstraint.RequiresChildConfiguration);
    setRequireUserAuthConstraint(required || false);
  }, [selectedApplication, values]);

  const setUserType = (userType: UserType) => {
    if (
      values.accessRequestApprovalType === AccessRequestApprovalType.ExternalAdministrator &&
      userType === UserType.Internal
    ) {
      setValues({
        ...values,
        userType,
        accessRequestApprovalType: AccessRequestApprovalType.LocalAdministrator
      });
      setFormAccessRequestApprovalTypeSeq(formAccessRequestApprovalTypeSeq + 1);
    } else {
      setValues({ ...values, userType });
    }
  };

  const onOrganisationGroupChange = useCallback((orgGroups: OrganizationGroup[]): void => {
    setSelectedOrganizationGroups(orgGroups);
  }, []);

  const renderOrganizationSelect = () => {
    if (!isUnlocksExternalApproverRightsRole) {
      return (
        <Dropdown
          form={form}
          readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeOrganizationGroup)}
          name='organizationGroupId'
          label='Forbeholdt eksterne brukere fra organisasjonsgruppe'
          placeholder=''
          options={organizationGroupOptions}
          onChange={(e: any) => {
            const val = e.target.value;
            setValues({
              ...values,
              organizationGroupIds: val,
              requireUserOrganizationInMinSide: isUnlocksExternalApproverRightsRole ? 'yes' : 'no'
            });
          }}
        />
      );
    }
    return (
      <OrganizationGroupSelector
        onChanged={onOrganisationGroupChange}
        organizationGroups={selectedOrganizationGroups}
        readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeOrganizationGroup)}
        selectOptions={organizationGroupOptions}
      />
    );
  };

  return (
    <Container fluid>
      <FormContainer form={form}>
        <Row>
          <Col sm={12} lg={6}>
            <DisplayErrors form={form} />
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <Input readonly={isUpdate} form={form} name='shortName' label='Kortnavn' placeholder='Kortnavn' />
          </Col>
          <Col sm={6} lg={4}>
            <Input
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeName)}
              form={form}
              name='name'
              label='Rollenavn'
              placeholder='Rollenavn'
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <Dropdown
              form={form}
              name='applicationId'
              label='Applikasjon'
              readonly={(isUpdate && !hasAccess(AdgangAccessControl.RoleEditChangeApplication)) || isReadOnly}
              options={applicationOptions}
              onChange={(e: any) => {
                const val = e.target.value as string;
                setValues({ ...values, applicationId: val });
              }}
            />
          </Col>
          <Col sm={6} lg={4}>
            <RadioGroup
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeCanBeRequestedByUsers)}
              name='canBeRequestedByUsers'
              label='Er rollen søkbar i MinSide?'
              options={[
                { id: 'yes', text: 'Ja' },
                { id: 'no', text: 'Nei' }
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <RadioGroup
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeUserType)}
              name='userType'
              label='Hvilken brukergruppe er rollen ment for?'
              options={[
                { id: UserType.Internal, text: 'Interne ansatte' },
                { id: UserType.External, text: 'Eksterne brukere' }
              ]}
              onChange={(e) => setUserType(e as UserType)}
            />
          </Col>
          <Col sm={6} lg={4}>
            {requireUserAuthConstraint && (
              <Dropdown
                readonly={
                  isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeUserAuthenticationConstraint)
                }
                form={form}
                name='userAuthenticationConstraint'
                label='Type brukerautentisering'
                options={userAuthenticationConstraints}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            {values.userType === UserType.External ? renderOrganizationSelect() : null}
          </Col>
          {/* <Col sm={6} lg={4}>
            {values.userType === UserType.External &&
              selectedOrganizationGroup &&
              [
                OrganizationGroupType.Kommune,
                OrganizationGroupType.Statsforvalteren,
                OrganizationGroupType.DomesticCompany,
                OrganizationGroupType.ForeignCompany
              ].includes(selectedOrganizationGroup?.organizationGroupType as OrganizationGroupType) && (
                <RadioGroup
                  form={form}
                  readonly={
                    isReadOnly ||
                    !hasAccess(AdgangAccessControl.RoleEditChangeRequireOrganizationGroupInMinSide)
                  }
                  name='requireUserOrganizationInMinSide'
                  label='Skal rollen knyttes unikt mot organisasjon i organisasjonsgruppe'
                  options={[
                    { id: 'yes', text: 'Ja' },
                    { id: 'no', text: 'Nei' }
                  ]}
                />
              )}
          </Col> */}
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <RadioGroup
              form={form}
              key={formAccessRequestApprovalTypeSeq}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeAccessRequestApprovalType)}
              name='accessRequestApprovalType'
              label='Skal rolle godkjennes av lokal administrator?'
              options={[
                { id: AccessRequestApprovalType.LocalAdministrator, text: 'Ja' },
                { id: AccessRequestApprovalType.NoApproval, text: 'Nei' }
              ].concat(
                values.userType === UserType.External
                  ? [{ id: AccessRequestApprovalType.ExternalAdministrator, text: 'Ekstern godkjenner' }]
                  : []
              )}
              onChange={(e: any) => {
                setValues({ ...values, accessRequestApprovalType: e as AccessRequestApprovalType });
              }}
            />
          </Col>
          <Col sm={6} lg={4}>
            <Checkbox
              form={form}
              name='requiresMfa'
              label='Krever rollen multi-faktor autentisering for eksterne brukere?'
              question=''
              disabled={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeRequiresMfa)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6} lg={4}>
            <TextArea
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeDescription)}
              name='description'
              label='Beskrivelse til bruk i MinSide'
              placeholder='Beskrivelse'
            />
          </Col>
          <Col sm={6} lg={4}>
            <TextArea
              form={form}
              readonly={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangeTags)}
              name='tags'
              label='Stikkord til bruk i MinSide'
              placeholder='Legg til stikkord'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {!showAddPermissions && (
              <Button
                disabled={isReadOnly || !hasAccess(AdgangAccessControl.RoleEditChangePermissions)}
                type='button'
                text=' + Legg til tilganger'
                onClick={() => setShowAddPermissions(true)}
                styleType='light'
              />
            )}
          </Col>
          <Col sm={12} lg={4}>
            <div className='float-right'>
              <Button disabled={isReadOnly} type='submit' text={onSaveLabel} />
              <Button
                disabled={isReadOnly}
                type='reset'
                text='Avbryt'
                styleType='light'
                onClick={() => history.push(RoutePaths.roles)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {!showAddPermissions && values.permissionIds?.length > 0 && permissions && (
              <Table>
                <thead>
                  <tr>
                    <Th title='Tilgangsnavn' />
                    <Th title='Beskrivelse' />
                    <Th title='Fjern' />
                  </tr>
                </thead>
                <tbody>
                  {values.permissionIds
                    .map((p) => permissions.filter((o) => o.permissionId?.toString() === p)[0])
                    .sort((p1, p2) => (p1.name || '').localeCompare(p2.name || ''))
                    .map((permission) => {
                      return (
                        <tr key={permission.permissionId}>
                          <td>{permission.name}</td>
                          <td>{permission.description}</td>
                          <td>
                            <IconCross
                              style={{ width: '12px' }}
                              onClick={() =>
                                setValues({
                                  ...values,
                                  permissionIds: values.permissionIds.filter(
                                    (v) => v !== permission.permissionId?.toString()
                                  )
                                })
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
            {showAddPermissions && (
              <>
                <b>Legg til tilgang</b>
                <PermissionsMultiSelect
                  applicationOptions={permissionApplicationOptions}
                  permissions={permissions.filter(
                    (p) => !values.permissionIds.some((pm) => +pm === p.permissionId)
                  )}
                  form={form}
                  name='permissionIds'
                  label='Velg tilganger'
                  triggerOnChangeAlways
                  isInitiallyOpen
                  onChange={(p) => {
                    let newPermissions = values.permissionIds;
                    newPermissions = newPermissions.concat(p);
                    newPermissions = Array.from(new Set(newPermissions).values()); // Make unique
                    setValues({ ...values, permissionIds: newPermissions });
                    setShowAddPermissions(false);
                  }}
                  onClose={() => setShowAddPermissions(false)}
                  okLabel='Velg'
                />
              </>
            )}
          </Col>
        </Row>
      </FormContainer>
    </Container>
  );
}

export default RoleForm;
