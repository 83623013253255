/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessRequestApprovalType,
    AccessRequestApprovalTypeFromJSON,
    AccessRequestApprovalTypeFromJSONTyped,
    AccessRequestApprovalTypeToJSON,
    AdgangAccessControl,
    AdgangAccessControlFromJSON,
    AdgangAccessControlFromJSONTyped,
    AdgangAccessControlToJSON,
    PermissionResponse,
    PermissionResponseFromJSON,
    PermissionResponseFromJSONTyped,
    PermissionResponseToJSON,
    UserAuthenticationConstraint,
    UserAuthenticationConstraintFromJSON,
    UserAuthenticationConstraintFromJSONTyped,
    UserAuthenticationConstraintToJSON,
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse {
    /**
     * 
     * @type {Array<AdgangAccessControl>}
     * @memberof RoleResponse
     */
    accessControls?: Array<AdgangAccessControl> | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    applicationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    applicationName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    canBeRequestedByUsers?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    description?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    isGlobal?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RoleResponse
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    shortName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RoleResponse
     */
    tags?: string | null;
    /**
     * 
     * @type {UserType}
     * @memberof RoleResponse
     */
    userType?: UserType;
    /**
     * 
     * @type {UserAuthenticationConstraint}
     * @memberof RoleResponse
     */
    userAuthenticationConstraint?: UserAuthenticationConstraint;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requireUserOrganizationInMinSide?: boolean | null;
    /**
     * 
     * @type {AccessRequestApprovalType}
     * @memberof RoleResponse
     */
    accessRequestApprovalType?: AccessRequestApprovalType;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    requiresMfa?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    unlocksExternalApproverRights?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RoleResponse
     */
    isSystemRole?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleResponse
     */
    organizationGroupIds?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleResponse
     */
    permissionIds?: Array<number> | null;
    /**
     * 
     * @type {Array<PermissionResponse>}
     * @memberof RoleResponse
     */
    permissions?: Array<PermissionResponse> | null;
}

export function RoleResponseFromJSON(json: any): RoleResponse {
    return RoleResponseFromJSONTyped(json, false);
}

export function RoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessControls': !exists(json, 'accessControls') ? undefined : (json['accessControls'] === null ? null : (json['accessControls'] as Array<any>).map(AdgangAccessControlFromJSON)),
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'applicationName': !exists(json, 'applicationName') ? undefined : json['applicationName'],
        'canBeRequestedByUsers': !exists(json, 'canBeRequestedByUsers') ? undefined : json['canBeRequestedByUsers'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isGlobal': !exists(json, 'isGlobal') ? undefined : json['isGlobal'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'userType': !exists(json, 'userType') ? undefined : UserTypeFromJSON(json['userType']),
        'userAuthenticationConstraint': !exists(json, 'userAuthenticationConstraint') ? undefined : UserAuthenticationConstraintFromJSON(json['userAuthenticationConstraint']),
        'requireUserOrganizationInMinSide': !exists(json, 'requireUserOrganizationInMinSide') ? undefined : json['requireUserOrganizationInMinSide'],
        'accessRequestApprovalType': !exists(json, 'accessRequestApprovalType') ? undefined : AccessRequestApprovalTypeFromJSON(json['accessRequestApprovalType']),
        'requiresMfa': !exists(json, 'requiresMfa') ? undefined : json['requiresMfa'],
        'unlocksExternalApproverRights': !exists(json, 'unlocksExternalApproverRights') ? undefined : json['unlocksExternalApproverRights'],
        'isSystemRole': !exists(json, 'isSystemRole') ? undefined : json['isSystemRole'],
        'organizationGroupIds': !exists(json, 'organizationGroupIds') ? undefined : json['organizationGroupIds'],
        'permissionIds': !exists(json, 'permissionIds') ? undefined : json['permissionIds'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(PermissionResponseFromJSON)),
    };
}

export function RoleResponseToJSON(value?: RoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessControls': value.accessControls === undefined ? undefined : (value.accessControls === null ? null : (value.accessControls as Array<any>).map(AdgangAccessControlToJSON)),
        'applicationId': value.applicationId,
        'applicationName': value.applicationName,
        'canBeRequestedByUsers': value.canBeRequestedByUsers,
        'description': value.description,
        'isGlobal': value.isGlobal,
        'name': value.name,
        'roleId': value.roleId,
        'shortName': value.shortName,
        'tags': value.tags,
        'userType': UserTypeToJSON(value.userType),
        'userAuthenticationConstraint': UserAuthenticationConstraintToJSON(value.userAuthenticationConstraint),
        'requireUserOrganizationInMinSide': value.requireUserOrganizationInMinSide,
        'accessRequestApprovalType': AccessRequestApprovalTypeToJSON(value.accessRequestApprovalType),
        'requiresMfa': value.requiresMfa,
        'unlocksExternalApproverRights': value.unlocksExternalApproverRights,
        'isSystemRole': value.isSystemRole,
        'organizationGroupIds': value.organizationGroupIds,
        'permissionIds': value.permissionIds,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(PermissionResponseToJSON)),
    };
}


