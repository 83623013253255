import React, { useState } from 'react';
import Page from 'components/page/Page';
import {
  GenericValidationError,
  UserAccessType,
  ExternalUserStatus,
  ExternalUserDetailResponse,
  UserType,
  ResourceFilter,
  AdgangAccessControl
} from 'api/adgang/models';
import {
  LoadingSpinner,
  useFetch,
  useBoundForm,
  Checkbox,
  Button,
  ConfirmationModal,
  formatDatetime
} from 'common.ui';
import RoleSelectorModal from 'components/roles/RoleSelectorModal';
import PermissionsSelectorModal from 'components/permissions/PermissionsSelectorModal';
import { externalUsersClient, usersClient } from 'api/AdgangClients';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { UserProperty } from 'components/users/UserProperty';
import { UserAccessTable, UserAccessResponseOrganization } from 'components/users/UserAccessTable';
import { toIdPortenInfo } from 'helpers/users/toIdPortenInfo';
import { toFullname } from 'helpers/users/toFullname';

import './ExternalUser.scss';
import { UserEventsTable } from 'components/users/UserEventsTable';
import { useAccessControlList } from 'hooks/access/useAccessControlList';

type UserStatusFormValue = {
  status: string;
};
interface IProps {
  user: ExternalUserDetailResponse;
}
interface IParams {
  id: string;
}
function ExternalUser() {
  const { id } = useParams<IParams>();
  const [user] = useFetch(
    async () =>
      externalUsersClient.apiExternalUsersUserIdGet({
        userId: id
      }),
    undefined,
    false,
    [id]
  );

  return (
    <Page header='Ekstern bruker'>{user ? <ExternalUserScreen user={user} /> : <LoadingSpinner />}</Page>
  );
}

function ExternalUserScreen({ user }: IProps) {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showInheritedAccess, setShowInheritedAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState<UserAccessResponseOrganization>();
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [seq, setSeq] = useState(0);
  const [notifyEmail, setNotifyEmail] = useState(false);
  const history = useHistory();
  const [apiErrors, setApiErrors] = useState<GenericValidationError>();
  const [, hasAccess] = useAccessControlList();
  const [values] = useState<UserStatusFormValue>({
    status: user.status ?? ''
  });

  const [access] = useFetch(
    async () =>
      user?.userId
        ? usersClient.apiUsersUserIdAccessGet({
            userId: user.userId
          })
        : null,
    undefined,
    false,
    [user?.userId, seq]
  );

  const userStatusOptions = [
    { id: ExternalUserStatus.Active as string, text: 'Aktiv' },
    { id: ExternalUserStatus.Inactive as string, text: 'Inaktiv' }
  ];

  const { form, FormContainer, Dropdown, DisplayErrors } = useBoundForm<UserStatusFormValue>({
    onSubmit: async (model) => {
      try {
        if (user.userId) {
          await externalUsersClient.apiExternalUsersUserIdStatusPut({
            userId: user.userId,
            externalUserStatusUpdateRequest: {
              status: model.status as ExternalUserStatus
            }
          });
          history.go(0);
        }
      } catch (e) {
        if (e.json) {
          const result = (await e.json()) as GenericValidationError;
          if (result) {
            setApiErrors(result);
          }
        }
      }
    },
    errors: apiErrors,
    model: values
  });

  async function onDeleteConfirmed() {
    if (deleteAccess && user?.userId && deleteAccess.UserAccess.id) {
      if (deleteAccess.UserAccess.type === UserAccessType.Role) {
        if (deleteAccess.Organization?.organizationId) {
          await usersClient.apiUsersUserIdRolesRoleIdOrganizationOrganizationIdDelete({
            userId: user.userId,
            roleId: deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await usersClient.apiUsersUserIdRolesRoleIdDelete({
            userId: user.userId,
            roleId: deleteAccess.UserAccess.id
          });
        }
      }
      if (deleteAccess.UserAccess.type === UserAccessType.Permission) {
        if (deleteAccess.Organization?.organizationId) {
          await usersClient.apiUsersUserIdPermissionsPermissionIdOrganizationOrganizationIdDelete({
            userId: user.userId,
            permissionId: deleteAccess.UserAccess.id,
            organizationId: deleteAccess.Organization.organizationId
          });
        } else {
          await usersClient.apiUsersUserIdPermissionsPermissionIdDelete({
            userId: user.userId,
            permissionId: deleteAccess.UserAccess.id
          });
        }
      }
      setSeq(seq + 1);
      setDeleteAccess(undefined);
    }
  }

  const idPortenUser = user && toIdPortenInfo(user);
  const userFullname = toFullname(user?.firstName, user?.surname);
  const idPortenFullname = toFullname(idPortenUser?.firstName, idPortenUser?.lastName);

  return (
    <>
      {access ? (
        <>
          <ConfirmationModal
            header='Fjern tilgang'
            show={deleteAccess !== undefined}
            onCancel={() => setDeleteAccess(undefined)}
            onAccept={onDeleteConfirmed}
          >
            Er du sikker på at du vil fjerne denne{' '}
            {deleteAccess?.UserAccess?.type === UserAccessType.Permission && 'tilgangen '}
            {deleteAccess?.UserAccess?.type === UserAccessType.Role && 'rollen '}
            fra brukeren?
          </ConfirmationModal>

          <PermissionsSelectorModal
            show={showPermissionModal}
            header='Legg til tilgang'
            onCancel={() => setShowPermissionModal(false)}
            okText='Legg til tilgang'
            userId={user.userId || undefined}
            userType={UserType.External}
            resourceFilter={ResourceFilter.ExternalUserAccessAssignment}
            onPermissionSelected={async (permission) => {
              if (user.userId) {
                await usersClient.apiUsersUserIdPermissionsPost({
                  userId: user.userId,
                  addUserPermissionRequest: {
                    notifyUserByEmail: notifyEmail,
                    permissionId: parseInt(permission.id, 10)
                  }
                });
                setSeq(seq + 1);
              }
              setShowPermissionModal(false);
            }}
            additionalJsx={
              <Checkbox
                id='notifyEmail'
                key={notifyEmail ? '1' : '0'}
                onChange={() => setNotifyEmail(!notifyEmail)}
                checked={notifyEmail}
                labelText='Send epost til bruker om tilgangstildelingen'
              />
            }
          />

          <RoleSelectorModal
            show={showRoleModal}
            header='Legg til rolle'
            onCancel={() => setShowRoleModal(false)}
            okText='Legg til rolle'
            userId={user.userId || undefined}
            userType={UserType.External}
            resourceFilter={ResourceFilter.ExternalUserAccessAssignment}
            onRoleSelected={async (role, organizationId) => {
              if (user.userId) {
                await usersClient.apiUsersUserIdRolesPost({
                  userId: user.userId,
                  addUserRoleRequest: {
                    notifyUserByEmail: notifyEmail,
                    roleId: parseInt(role.id, 10),
                    organizationId: organizationId !== null ? parseInt(organizationId, 10) : null
                  }
                });
                setSeq(seq + 1);
              }
              setShowRoleModal(false);
            }}
            additionalJsx={
              <Checkbox
                id='notifyEmail'
                key={notifyEmail ? '1' : '0'}
                onChange={() => setNotifyEmail(!notifyEmail)}
                checked={notifyEmail}
                labelText='Send epost til bruker om rolletildelingen'
              />
            }
          />

          <FormContainer form={form}>
            <Container fluid>
              <Row>
                <Col lg='7'>
                  <div className='content-box'>
                    <UserProperty label='Navn' value={userFullname} />
                    <UserProperty label='Epost' value={user.email} />
                    {user.mfaPhoneNumber && (
                      <UserProperty
                        label='Mobiltelefonnummer'
                        value={`${user.mfaPhoneNumber} (dette telefonnummeret brukes til SMS-kode ved pålogging)`}
                      />
                    )}

                    <UserProperty label='&nbsp;' value='' />

                    {idPortenUser && idPortenFullname && idPortenFullname !== userFullname && (
                      <UserProperty label='Navn (ID-porten)' value={`${idPortenFullname}`} />
                    )}
                    {idPortenUser && idPortenUser.epost && user.email !== idPortenUser.epost && (
                      <UserProperty label='Epost (ID-porten)' value={idPortenUser?.epost} />
                    )}

                    {user.userKrrInfo && user.userKrrInfo.email && user.userKrrInfo.email !== user.email && (
                      <UserProperty label='Epost (KRR)' value={user.userKrrInfo?.email} />
                    )}
                    {user.userKrrInfo && user.userKrrInfo.mobileNr && (
                      <UserProperty label='Telefon (KRR)' value={user.userKrrInfo?.mobileNr} />
                    )}
                    {user.statsforvalterenOrganizationGroup?.organizations?.length && (
                      <UserProperty
                        label='Statsforvalteren org.'
                        value={user.statsforvalterenOrganizationGroup.organizations[0].name}
                      />
                    )}
                    <UserProperty label='Sist innlogget' value={formatDatetime(user.lastLoggedInOn)} />
                  </div>
                </Col>

                {hasAccess(AdgangAccessControl.ExternalUserEdit) && (
                  <Col lg='2'>
                    <DisplayErrors form={form} />
                    <Dropdown form={form} name='status' label='Status' options={userStatusOptions} />
                  </Col>
                )}
                {hasAccess(AdgangAccessControl.ExternalUserEdit) && (
                  <Col lg='3'>
                    <div className='status-button'>
                      <Button type='submit' styleType='light' text='Endre status' />
                    </div>
                  </Col>
                )}
              </Row>

              <UserAccessTable
                access={access}
                idPortenUserId={idPortenUser?.userId}
                showInheritedAccess={showInheritedAccess}
                toggleShowInheritedAccess={() => setShowInheritedAccess(!showInheritedAccess)}
                addPermission={() => {
                  setNotifyEmail(true);
                  setShowPermissionModal(true);
                }}
                addRole={() => {
                  setNotifyEmail(true);
                  setShowRoleModal(true);
                }}
                onDeleteAccess={(a) => setDeleteAccess(a)}
              />

              {user && user.userId && <UserEventsTable userId={user.userId} refresh={seq} />}
            </Container>
          </FormContainer>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
}

export default ExternalUser;
