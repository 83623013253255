import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { meClient } from 'api/AdgangClients';
import { Footer, Header, LoadingSpinner, useFetch } from 'common.ui';
import { withAuth } from 'auth/withAuth';
import { useMsal } from 'auth/MsalContext';
import { useAccessControlList } from 'hooks/access/useAccessControlList';
import PageNotFound from 'screens/PageNotFound';
import { flattenRoutes, RouteItem, toSidebarItem, validRoutes } from 'helpers/routing';
import { RouteConfig } from 'RouteConfig';
import { MainContent } from 'components/pageTemplate/MainContent';
import RoutePaths from 'RoutePaths';
import ClearCookies from 'screens/devtools/ClearCookies';
import { AdgangAccessControl } from 'api/adgang';
import logoImage from './assets/images/MdirLogo_Main.svg';
import accountImage from './assets/images/person-header.png';

const auth = (c: any) => {
  return () => {
    const { authInProgress } = useMsal();

    if (authInProgress) return <LoadingSpinner />;

    return <>{withAuth(c)(c)}</>;
  };
};

const allRoutes = flattenRoutes(RouteConfig);
const homeRoute = allRoutes.filter((x) => x.link === RoutePaths.home)[0];

const RoutesRaw = () => {
  const [me] = useFetch(async () => meClient.apiMeAccessControlListGet(), undefined, false);

  const [acl, hasAccess] = useAccessControlList();
  const { isAuthenticated, user, authInProgress, login, logout } = useMsal();

  const hasNoAcl = () => {
    if (acl === undefined) return false;
    return acl.length === 0;
  };

  const hasAccessToItem = (item: RouteItem) => {
    if (item.link === RoutePaths.home) {
      return hasNoAcl();
    }

    if (!acl) return false;
    if (!item.requiredPermission) return true;
    return acl.some((a) => a === item.requiredPermission);
  };

  const allowedRoutes = validRoutes(RouteConfig, hasAccessToItem);
  const sidebarItems = allowedRoutes.map((rc) => toSidebarItem(rc));
  const registerRoutes = allRoutes.filter((x) => x.link !== RoutePaths.home);

  return !authInProgress ? (
    <>
      {me && (
        <Router>
          <>
            <Header
              applicationName='Adgang'
              logoImage={logoImage}
              accountImage={accountImage}
              authInProgress={authInProgress}
              isAuthenticated={isAuthenticated}
              activeUser={user}
              login={login}
              logout={logout}
            />

            <MainContent routes={sidebarItems}>
              <>
                <Switch>
                  <Route
                    exact
                    key={homeRoute.link}
                    path={homeRoute.link}
                    render={() => {
                      if (hasNoAcl()) return React.createElement(homeRoute.component);

                      if (hasAccess(AdgangAccessControl.ApplicationsView))
                        return <Redirect to={RoutePaths.applications} />;

                      if (allowedRoutes[0]?.component) return React.createElement(allowedRoutes[0].component);

                      return <div />;
                    }}
                  />

                  {registerRoutes.map((r) => (
                    <Route
                      exact
                      key={r.link}
                      path={r.link}
                      component={hasAccessToItem(r) ? r.component : () => <div />}
                    />
                  ))}

                  <Route
                    exact
                    key={RoutePaths.clearCookies}
                    path={RoutePaths.clearCookies}
                    component={ClearCookies}
                  />

                  {/* 404 route */}
                  <Route component={PageNotFound} />
                </Switch>

                <Footer logoImage={logoImage} />
              </>
            </MainContent>
          </>
        </Router>
      )}
    </>
  ) : (
    <LoadingSpinner />
  );
};

const Routes = auth(RoutesRaw);

export default Routes;
