import { OrganizationGroupResponse } from 'api/adgang';
import { organizationGroupsClient } from 'api/AdgangClients';
import { SelectOption, useFetch } from 'common.ui';

export type OrganizationGroupsResult = [SelectOption[] | undefined, OrganizationGroupResponse[] | undefined];

export function useOrganizationGroups(): OrganizationGroupsResult {
  const [fetchedOrganizationGroups] = useFetch<OrganizationGroupsResult>(
    () => fetchOrganizationGroups(),
    [undefined, undefined] as OrganizationGroupsResult,
    false,
    []
  );

  const fetchOrganizationGroups = async (): Promise<OrganizationGroupsResult> => {
    const groups = await organizationGroupsClient.apiOrganizationGroupsGet({
      pageNumber: 1,
      pageSize: 9999
    });

    if (!groups.data) {
      return [undefined, undefined] as OrganizationGroupsResult;
    }

    const data = groups.data || [];

    const options = data
      .sort((o1, o2) => (o1.name || '').localeCompare(o2.name || ''))
      .map((org) => {
        return {
          id: org.organizationGroupId?.toString(),
          text: org.name
        } as SelectOption;
      });

    return [options, data];
  };

  return fetchedOrganizationGroups;
}
