/* tslint:disable */
/* eslint-disable */
/**
 * Adgang API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessRequestApprovalType {
    LocalAdministrator = 'LocalAdministrator',
    ExternalAdministrator = 'ExternalAdministrator',
    NoApproval = 'NoApproval'
}

export function AccessRequestApprovalTypeFromJSON(json: any): AccessRequestApprovalType {
    return AccessRequestApprovalTypeFromJSONTyped(json, false);
}

export function AccessRequestApprovalTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRequestApprovalType {
    return json as AccessRequestApprovalType;
}

export function AccessRequestApprovalTypeToJSON(value?: AccessRequestApprovalType | null): any {
    return value as any;
}

